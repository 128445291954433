body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.NavBar {
  color: white;
  background-color: #5e4cd6;
  padding: 1px;
  margin: 0;
}

.block.wrapper
{
  padding: 10px;
  max-width: 90%;
}

.signature{
  background-color: #5e4cd6;
  color: white;
  padding-top: 5px;
  padding-bottom: 10px;
}

a
{
  color: #00ffc3;
}
img{
  max-width: 80%;
}

.TotalHours {
  background-color: yellow;
}

.inputNumber
{
  width: 40px;
}

input, select
{
  border: 3px solid black;
  border-radius: 3px;
  font-weight: bold;

}